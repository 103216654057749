<template>
  <div>
    <b-card :style="'min-height: 500px; background: ' + d_treeListData.style.backGround">
      <b-row style="margin: 0px;">
        <b-col v-for="(x, x_ind) in d_treeListData.list" cols="12" sm="12" lg="4">
          <b-card footer-class="px-3 py-2" style="height: 100%;">
            <b-row style="margin: 5px;">
              <b-col sm="12" md="12">
                <span style="font-weight: bold; font-size: 50px"> {{ x.wdmr.label }} </span>
              </b-col>
            </b-row>
            <b-row style="margin: 5px;">
              <template v-if="f_showSelectedSubsOldPrice(x)">
                <b-col sm="12" md="5">
                  <span style="text-align: center; font-weight: bold; font-size: 35px; color: red; text-decoration: line-through;"> {{ f_showSelectedSubsOldPrice(x) }} </span>
                </b-col>
              </template>
              <template v-else>
                <b-col sm="12" md="5">
                </b-col>
              </template>
              <b-col sm="12" md="7">
                <span style="text-align: center; font-weight: bold; font-size: 50px; color: green"> {{ f_showSelectedSubsPrice(x) }} </span>
              </b-col>
            </b-row>
            <b-row style="margin: 5px;">
              <b-col sm="12" md="5">
              </b-col>
              <b-col sm="12" md="7">
                <b-form-select :id="x.key" v-model="d_selectedSubsTimeIndList[x.key]" @change="$forceUpdate()">
                  <option v-for="(time, time_ind) in x.wdmr.data.general.subscription_time.list" :value="time_ind">{{ time.is_subscription_time_limited.val.value === 'limited' ? ' / ' + time.subscription_time_number.val + ' ' + time.subscription_time_type.val.label : ' / Lifetime' }}</option>
                </b-form-select>
              </b-col>
            </b-row>
            <b-row style="margin: 5px;">
            </b-row>
            <b-row style="margin: 5px;">
              <b-col sm="12" md="12" class="text-center">
                <span style="font-weight: bold; font-size: 25px;"> Avantajlar </span>
              </b-col>
            </b-row>
            <b-row style="margin: 5px;">
              <b-col sm="12" md="12">
                <template v-for="(perm, perm_ind) in x.wdmr.data.general.selected_permissions">
                  {{ perm.name.label }}
                  <template v-if="perm.detail && perm.detail.is_permission_limited && perm.detail.is_permission_limited.val && perm.detail.is_permission_limited.val.value === 'yes' && perm.detail.permission_limit && perm.detail.permission_limit.val && perm.detail.permission_limit.val !== ''">
                  	<small>( {{ perm.detail.permission_limit.val }} )</small>
                  </template>
                  <br>
                </template>
              </b-col>
            </b-row>
            <div slot="footer">
              <b-button block variant="primary" class="payment-button" @click="f_buyPackage(x)"><i class="fa fa-shopping-cart float-right font-lg"></i>Satın Al</b-button>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </b-card>
    <b-card style="background: black;" no-body>
      <web-bottom></web-bottom>
    </b-card>
  </div>
</template>

<script>
import WebBottom from '@/components/widgets/WebBottom';
import GlobalService from '@/services/global';
import WdmService from '@/services/wdm';
import { mapGetters } from 'vuex';
// import Vue from 'vue';
// import { API_BASE_URL } from '@/config';
import store from '@/store';
import {
  default as router
} from '@/router';

export default {
  name: 'Subscription',
  computed: {
    ...mapGetters({})
  },
  mounted: function () {},
  data () {
    return {
      d_selectedSubsTimeIndList: {},
      d_showTree: false,
      d_treeListData: {
        'style': {
          'menu': {
            'background': '#ededed',
            'padding': '0',
            'boxShadow': 'rgb(164 219 221) -1px -1px 1px 1px',
            'border': 'solid 1px black'
          },
          'backGround': 'white',
          'item': {
            'background': '#ededed',
            'color': 'black',
            'margin': '3',
            'width': '300',
            'padding': '3',
            // 'boxShadow': 'rgb(164 219 221) -1px -1px 1px 1px',
            'boxShadow': '',
            'minHeight': '',
            // 'border': 'solid 1px black',
            'border': '',
            'left_plus': '10'
          }
        },
        'template': {
          'type': '2'
        },
        'list': [],
        'items': [],
        'parent_wdmr_data': '',
        'parent': { // parent and child field means: we can record a child to this parent. But other possible_relation means: this child's key can be added.
          'key': 'wdm232_38',
          'type': 'wdm232',
          'bucket': 'wisdom'
        },
        'child': {
          'reference_wdm_key': 'wdm_wisdom_wdm244_v2.0',
          'type': 'wdm244',
          'bucket': 'wisdom',
          'other_possible_relations': []
        }
      }
    };
  },
  created: function () {
    this.f_getWdmrTreeList();
  },
  filters: {},
  methods: {
    f_showSelectedSubsOldPrice: function (list_item) {
      let time = list_item.wdmr.data.general.subscription_time.list[this.d_selectedSubsTimeIndList[list_item.key]];
      if (time.subscription_time_oldprice && time.subscription_time_oldprice.val && time.subscription_time_oldprice.val !== '') {
        return time.subscription_time_oldprice.val + ' ' + time.subscription_time_currency.val.label;
      } else {
        return false;
      }
    },
    f_showSelectedSubsPrice: function (list_item) {
      let time = list_item.wdmr.data.general.subscription_time.list[this.d_selectedSubsTimeIndList[list_item.key]];
      return time.subscription_time_price.val + ' ' + time.subscription_time_currency.val.label;
    },
    f_getWdmrTreeList: function () {
      let data = {
        'parent_wdm': this.d_treeListData.parent,
        'child_wdm': this.d_treeListData.child,
        'relation_type': 'keys',
        'type': 'get_wdmr_data', // only_relation_list or get_wdmr_data
        'layer_type': 'first' // first or all
      };
      WdmService.get_wdmr_tree_list(data)
        .then(resp => {
          if (resp.data.status === "success") {
            this.d_treeListData.list = resp.data.result.list;
            let data = {
              'key': this.d_treeListData.parent.key,
              'bucket': this.d_treeListData.parent.bucket
            };
            for (let i in this.d_treeListData.list) {
              this.d_selectedSubsTimeIndList[this.d_treeListData.list[i].key] = 0;
            }
            // console.log(data);
            GlobalService.get_one_cb_document(data)
              .then(resp => {
                let msg = '';
                if (resp.data.status === "success") {
                  this.d_treeListData.parent_wdmr_data = resp.data.result;
                  this.d_showTree = true;
                } else {
                  console.log('errorr get_one_cb_document : ', resp.data.message);
                }
              });
          } else {
            console.log('errorr get_wdmr_tree_list : ', resp.data.message);
          }
        });
    },
    f_buyPackage: function (info) {
      let route_data = {
        'name': 'payment',
        'path': 'payment',
        'query': {
          'wdmr244': info.key.split('_')[1],
          'selection': this.d_selectedSubsTimeIndList[info.key]
        }
      };
      this.$router.push(route_data);
    }
  },
  components: {
    WebBottom
  }
};

</script>

<style>


</style>

